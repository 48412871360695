<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- Referral -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-cog text-22px text-oPurple"></i>
          <span class="pl-3">Referral Bonus/Invitation</span>
        </div>
        <div :key="`referral_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in referralSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`referral_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="referral[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Maps -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mTeal" />
          <span class="pl-3">Map</span>
        </div>
        <div :key="`map_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in mapSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`map_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="maps[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- SMS Gateway -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mIndigo" />
          <span class="pl-3">Choose Sms gateway</span>
        </div>
        <div :key="`smsGateway_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in smsGatewaySettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`smsGateway_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :options="item.options"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="smsGateway[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- twilio -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mRed" />
          <span class="pl-3">Twilio-SMS Gateway</span>
        </div>
        <div :key="`twilio_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in twilioSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`twilio_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="twilio[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Misr -->
      <div class="mt-10 w-full">
        <div class="flex items-center mt-10 font-bold text-22px text-oCharcoal">
          <div class="circle-css-icon bg-mEmerald" />
          <span class="pl-3">Misr-SMS Gateway</span>
        </div>
        <div :key="`misr_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in misrSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`misr_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="misr[`${item.variable}`]"
              @save="onSave(`${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { GeneralSettingsConfig } from '@/config/SettingsConfig'
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'BasicSettings',
  components: {
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      referral: {
        invitation_amount: '',
        invitation_required_trips: '',
      },
      maps: {
        gmap_api_key: '',
        mapbox_api_key: '',
      },
      smsGateway: {
        sms_gateway: '',
      },
      twilio: {
        twilio_sid: '',
        twilio_auth_token: '',
        twilio_from_phone_number: '',
      },
      misr: {
        misr_api_username: '',
        misr_api_password: '',
        misr_msignature: '',
        misr_token: '',
      },
      inPlaceEditingState: {
        referral_invitation_amount: false,
        referral_invitation_required_trips: false,
        maps_gmap_api_key: false,
        maps_mapbox_api_key: false,
        twilio_twilio_sid: false,
        twilio_twilio_auth_token: false,
        twilio_twilio_from_phone_number: false,
      },
      referralSettings: [
        {
          type: 'number',
          label: 'Referral Bonus Amount',
          placeholder: 'e.g 10',
          description: `Enter the amount which will be earned by Inviter if Number of Trips conditions are fulfilled.`,
          variable: 'invitation_amount',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Number of trips required',
          placeholder: 'e.g 2',
          description: `Enter Number of Trips required for Invitee trigger Referral bonus amount.`,
          variable: 'invitation_required_trips',
          isEdit: false,
        },
      ],
      mapSettings: [
        {
          type: 'text',
          label: 'Google Maps API Key',
          placeholder: 'Google Maps API Key',
          description: `Enter Google Maps API Key which will be used to Load Map to Rider App, Dashboard and Distance Calculation.`,
          variable: 'gmap_api_key',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'MapBox API Key',
          placeholder: 'MapBox API Key',
          description: `Enter Mapbox API API Key which will be used to Load Map to Rider App, Dashboard and Distance Calculation.`,
          variable: 'mapbox_api_key',
          isEdit: false,
        },
      ],
      smsGatewaySettings: [
        {
          type: 'richselect',
          label: 'Choose Sms gateway',
          placeholder: 'Choose Sms gateway',
          options: [],
          description: `Please choose your SMS provider which will be used to send SMS or OTP for Log in to Rider. All SMS billing will be applied on chosen SMS Gateway`,
          variable: 'sms_gateway',
          isEdit: false,
        },
      ],
      twilioSettings: [
        {
          type: 'text',
          label: 'Account SID',
          placeholder: 'Twilio Account SID',
          description: `Enter Security Identifier which you will get from Twilio Profile.`,
          variable: 'twilio_sid',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Auth Token',
          placeholder: 'Twilio Auth Token',
          description: `Enter Authentication token which you will get from Twilio Profile.`,
          variable: 'twilio_auth_token',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'From Phone Number',
          placeholder: 'Twilio From Phone Number',
          description: `Enter from phone  Number which you need to buy from Twilio which will be used to send Phone Number identifier.`,
          variable: 'twilio_from_phone_number',
          isEdit: false,
        },
      ],
      misrSettings: [
        {
          type: 'text',
          label: 'API Username',
          placeholder: 'username',
          description: `Provide the API username provided by your SMS provider`,
          variable: 'misr_api_username',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'API Password',
          placeholder: 'password',
          description: `Enter API password`,
          variable: 'misr_api_password',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'MSignature',
          placeholder: 'Signature',
          description: `Provide Signature value provided by  SMS Service Provider`,
          variable: 'misr_msignature',
          isEdit: false,
        },
        {
          type: 'text',
          label: 'Token',
          placeholder: 'Token',
          description: `Provide token value provided by your  SMS Service Provider`,
          variable: 'misr_token',
          isEdit: false,
        },
      ],
    }
  },

  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'referral') {
          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'map') {
          this.referralSettings = this.referralSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'smsGateway') {
          this.referralSettings = this.referralSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'twilio') {
          this.referralSettings = this.referralSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'misr') {
          this.referralSettings = this.referralSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'referral') {
        this.referralSettings = this.referralSettings.map((item, itemIndex) => {
          if (id === `referral_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'referral'
      }
      if (idPrefix === 'map') {
        this.mapSettings = this.mapSettings.map((item, itemIndex) => {
          if (id === `map_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'map'
      }
      if (idPrefix === 'smsGateway') {
        this.smsGatewaySettings = this.smsGatewaySettings.map(
          (item, itemIndex) => {
            if (id === `smsGateway_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'smsGateway'
      }
      if (idPrefix === 'twilio') {
        this.twilioSettings = this.twilioSettings.map((item, itemIndex) => {
          if (id === `twilio_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'twilio'
      }
      if (idPrefix === 'misr') {
        this.misrSettings = this.misrSettings.map((item, itemIndex) => {
          if (id === `misr_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'misr'
      }
    })
  },
  async created() {
    this.$http
      .get(GeneralSettingsConfig.api.index(this.orgId))
      .then((res) => {
        console.log('settings', res.data)
        let data = res.data
        this.referral.invitation_amount = data.invitation_amount
        this.referral.invitation_required_trips = data.invitation_required_trips

        this.maps.gmap_api_key = data.gmap_api_key
        this.maps.mapbox_api_key = data.mapbox_api_key

        this.smsGateway.sms_gateway = data.sms_gateway
        this.smsGatewaySettings[0].options = data.sms_gateway_choices

        this.twilio.twilio_sid = data.twilio_sid
        this.twilio.twilio_auth_token = data.twilio_auth_token
        this.twilio.twilio_from_phone_number = data.twilio_from_phone_number

        this.misr.misr_api_username = data.misr_api_username
        this.misr.misr_api_password = data.misr_api_password
        this.misr.misr_msignature = data.misr_msignature
        this.misr.misr_token = data.misr_token

        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  methods: {
    async onSave(key, val) {
      this.counter++
      let toastTitle, toastText

      if (key === 'invitation_amount') {
        toastTitle = `Referral Bonus Amount`
        toastText = `Referral Bonus Amount is updated`
      }
      if (key === 'invitation_required_trips') {
        toastTitle = `Number of trips required`
        toastText = `Number of trips required is updated`
      }
      if (key === 'gmap_api_key') {
        toastTitle = `Number of trips required`
        toastText = `Google Maps API Key is updated`
      }
      if (key === 'mapbox_api_key') {
        toastTitle = `MapBox API Key`
        toastText = `MapBox API Key is updated`
      }
      if (key === 'sms_gateway') {
        toastTitle = `Sms gateway`
        toastText = `Sms gateway is updated`
      }
      if (key === 'twilio_sid') {
        toastTitle = `Account SID`
        toastText = `Account SID is updated`
      }
      if (key === 'twilio_auth_token') {
        toastTitle = `Auth Token`
        toastText = `Auth Token is updated`
      }
      if (key === 'twilio_from_phone_number') {
        toastTitle = `Twilio From Phone Number`
        toastText = `Twilio From Phone Number is updated`
      }
      if (key === 'misr_api_username') {
        toastTitle = `API Username`
        toastText = `API Username is updated`
      }
      if (key === 'misr_api_password') {
        toastTitle = `API Password`
        toastText = `API Password is updated`
      }
      if (key === 'misr_msignature') {
        toastTitle = `MSignature`
        toastText = `MSignature is updated`
      }
      if (key === 'misr_token') {
        toastTitle = `Token`
        toastText = `Token is updated`
      }

      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(GeneralSettingsConfig.api.update(this.orgId), data)
        .then((res) => {
          console.log(res)
          this.referralSettings = this.referralSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.mapSettings = this.mapSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.smsGatewaySettings = this.smsGatewaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.twilioSettings = this.twilioSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.misrSettings = this.misrSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.circle-css-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
.bg-mTeal {
  background-color: #32dc99;
}
.bg-mRed {
  background-color: #ff0d0d;
}
.bg-mIndigo {
  background-color: #4338ca;
}
.bg-mEmerald {
  background-color: #047857;
}
</style>
